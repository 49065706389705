import { Button, Pill, Text } from '@workos-inc/component-library';
import { formatDistance } from 'date-fns';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { DirectoryType } from '../../../../graphql/generated';
import { DirectoryProviderLabels } from '../../../../utils/constants';
import { AddDomain } from '../../../components/add-domain';
import { usePortalSession } from '../../../components/portal-session-provider';
import { ProviderSelection } from '../../../components/provider-selection';
import { Title } from '../../../components/typography';
import { PageTitle } from '../../../components/with-page-title/with-page-title';
import {
  CreationStateError,
  useCreateDirectory,
} from '../../../hooks/use-create-directory';
import { useEnabledDirectories } from '../hooks/use-enabled-directories';
import {
  SupportedDirectoryType,
  supportedDirectoryTypes,
} from '../interfaces/supported-directory-type';

export const DirectorySyncProviderSelectionPage = () => {
  const { setupLink, appName } = usePortalSession();
  const [creationState, createDirectory, resetState] = useCreateDirectory();
  const [isDomainInputRequired, setIsDomainInputRequired] = useState(false);
  const [selectedDirectoryType, setSelectedDirectoryType] =
    useState<SupportedDirectoryType>();
  const enabledDirectories = useEnabledDirectories(supportedDirectoryTypes);

  if (creationState.type === 'success') {
    return <Navigate replace to="/dsync/configure/1" />;
  }

  if (
    isDomainInputRequired &&
    selectedDirectoryType === DirectoryType.GoogleWorkspace
  ) {
    return (
      <AddDomain
        error={getDomainError(
          creationState.type === 'failed' ? creationState.value : undefined,
        )}
        onChangeProvider={() => {
          setIsDomainInputRequired(false);
          resetState();
        }}
        onSubmitAddDomain={(providedDomain) => {
          void createDirectory(selectedDirectoryType, providedDomain);
        }}
      />
    );
  }

  return (
    <PageTitle title="Configure Directory Sync">
      <div className="mx-auto flex max-w-7xl flex-col items-center py-8">
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 0.1 }}
        >
          <Title>Directory Sync Configuration</Title>
        </motion.div>
        <motion.div
          animate={{ opacity: 1 }}
          className="mt-1 mb-4 flex items-center gap-1"
          initial={{ opacity: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Text as="p" size="medium">
            Select the Directory Provider you want to use with
          </Text>
          <Pill className="max-w-none" size="small">
            {appName}
          </Pill>
        </motion.div>
        <ProviderSelection
          onValueChange={(directoryType: SupportedDirectoryType) => {
            setSelectedDirectoryType(directoryType);
          }}
          providerLabels={DirectoryProviderLabels}
          providers={enabledDirectories}
        />

        <motion.div
          animate={{ opacity: 1, scale: 1 }}
          className="mt-6"
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ delay: 0.3 }}
        >
          <Button
            className="px-6"
            disabled={
              !selectedDirectoryType || creationState.type === 'creating'
            }
            onClick={() => {
              if (selectedDirectoryType === DirectoryType.GoogleWorkspace) {
                setIsDomainInputRequired(true);
              } else if (selectedDirectoryType) {
                void createDirectory(selectedDirectoryType);
              }
            }}
            size="large"
          >
            Get Started →
          </Button>
        </motion.div>

        {setupLink ? (
          <Text className="mt-4 text-center">
            Setup Link access available for{' '}
            {formatDistance(new Date(setupLink.expiresAt), Date.now())} or until
            configured.
          </Text>
        ) : null}
      </div>
    </PageTitle>
  );
};

const getDomainError = (error?: CreationStateError) =>
  error?.errorCode === 'duplicate_domain' ? error.message : undefined;
