import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';
import { ConnectionType, DirectoryType } from '../../../graphql/generated';
import { ProviderCard } from './provider-card';

interface ProviderSelectionProps<T extends ConnectionType | DirectoryType>
  extends RadioGroupPrimitive.RadioGroupProps {
  providers: readonly T[];
  providerLabels?: { [key: string]: string };
}

export const ProviderSelection = <T extends ConnectionType | DirectoryType>({
  className,
  providers,
  providerLabels,
  ...props
}: ProviderSelectionProps<T>) => (
  <RadioGroupPrimitive.Root
    {...props}
    asChild
    className={classNames('grid gap-4 mb-4 grid-cols-4', className)}
    orientation="horizontal"
  >
    <motion.ul
      animate="show"
      className={classNames('grid gap-4 mb-4 grid-cols-4')}
      initial="hidden"
      variants={listVariants}
    >
      {providers.map((provider) => (
        <ProviderCard
          key={provider}
          data-testid={'provider-item__' + provider}
          label={providerLabels?.[provider]}
          value={provider}
        />
      ))}
    </motion.ul>
  </RadioGroupPrimitive.Root>
);

const listVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0.2,
      staggerChildren: 0.05,
    },
  },
};
