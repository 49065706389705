import { Button, Text, TextInput } from '@workos-inc/component-library';
import { motion } from 'framer-motion';
import React, { ChangeEvent, FC, useState } from 'react';
import { isDomain } from '../../utils/is-domain';
import { Title } from '../typography';

interface AddDomainProps {
  error?: string;
  onChangeProvider: () => void;
  onSubmitAddDomain(domain?: string): void;
}

export const AddDomain: FC<Readonly<AddDomainProps>> = ({
  error,
  onChangeProvider,
  onSubmitAddDomain,
}) => {
  const [newDomain, setNewDomain] = useState('');

  return (
    <form
      className="flex flex-col items-center"
      onSubmit={(e) => {
        e.preventDefault();

        onSubmitAddDomain(newDomain);
      }}
    >
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.1 }}
      >
        <Title>Add your domain</Title>
      </motion.div>

      <motion.div
        animate={{ opacity: 1 }}
        className="mt-4 mb-10"
        initial={{ opacity: 0 }}
        transition={{ delay: 0.2 }}
      >
        <Text as="p">
          Provide the primary domain associated with your Google account.
        </Text>
      </motion.div>

      <motion.div
        animate={{ opacity: 1 }}
        className="flex w-full max-w-xl flex-col items-center"
        initial={{ opacity: 0 }}
        transition={{ delay: 0.1 }}
      >
        <TextInput
          className="mt-4"
          id="missing-domain-input"
          name="Missing Domain"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setNewDomain(event.currentTarget.value);
          }}
          placeholder="example.com"
          value={newDomain}
        />

        {error && (
          <div className="mt-4 self-start text-red-darken">
            <Text inheritColor as="p">
              {error}
            </Text>
          </div>
        )}

        <Button className="mt-10" disabled={!isDomain(newDomain)} type="submit">
          Create Directory
        </Button>
      </motion.div>

      <motion.div
        animate={{ opacity: 1, scale: 1 }}
        initial={{ opacity: 0, scale: 0.5 }}
        transition={{ delay: 0.2 }}
      >
        <Button
          appearance="minimal"
          className="mt-4"
          onClick={() => {
            onChangeProvider();
          }}
        >
          Change Provider
        </Button>
      </motion.div>
    </form>
  );
};
