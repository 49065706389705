import { unreachable } from '@workos-inc/standard';
import { useState } from 'react';
import { DirectoryFragment } from '../../graphql/generated';
import { getNewDirectoryErrorCode } from '../../utils/get-error-code';
import { usePortalSession } from '../components/portal-session-provider';
import { useDsyncStore } from '../features/dsync/dsync-store-provider';
import { SupportedDirectoryType } from '../features/dsync/interfaces/supported-directory-type';
import { graphql } from '../utils/graphql';

export type CreationStateError = {
  error: unknown;
  message?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  errorCode?: (string & {}) | 'duplicate_domain';
};

export type DirectoryCreationState =
  | { type: 'idle' }
  | { type: 'creating' }
  | { type: 'success' }
  | { type: 'failed'; value: CreationStateError };
export const useCreateDirectory = () => {
  const { organization, untypedDirectory } = usePortalSession();
  const { setDsyncStore } = useDsyncStore();
  const [creationState, setCreationState] = useState<DirectoryCreationState>({
    type: 'idle',
  });

  const resetState = () => setCreationState({ type: 'idle' });

  const createDirectory = async (
    directoryType: SupportedDirectoryType,
    providedDomain?: string,
  ): Promise<void> => {
    setCreationState({ type: 'creating' });
    const orgDomain = organization?.domains?.[0]?.domain;
    const domain = providedDomain || orgDomain;
    let directory: DirectoryFragment | undefined;

    try {
      if (untypedDirectory) {
        const { data } = await graphql().CreateDirectoryFromUntyped({
          input: {
            directoryId: untypedDirectory.id,
            type: directoryType,
          },
        });
        const result = data?.portal_createDirectoryFromUntyped;
        switch (result?.__typename) {
          case 'Portal_DirectoryCreated': {
            directory = result.directory;
            break;
          }
          case 'DirectoryNotFound': {
            throw new Error(`Directory '${result.directoryId}' not found.`);
          }
          case 'DirectoryIsAlreadyTyped': {
            throw new Error(
              `Directory '${result.directoryId}' already has a type.`,
            );
          }
          case undefined: {
            throw new Error('No GraphQL response.');
          }
          default:
            return unreachable(result);
        }
      } else {
        const { data } = await graphql().CreateDirectory({
          name: organization?.name || '',
          type: directoryType,
          domain,
        });

        directory = data?.portal_createDirectory;
        setDsyncStore({ directory });
        setCreationState({ type: 'success' });
      }
    } catch (error) {
      const errorCode = getNewDirectoryErrorCode(error);
      const message =
        errorCode === 'duplicate_domain'
          ? `A directory for "${domain}" already exists.`
          : undefined;

      setCreationState({
        type: 'failed',
        value: {
          error,
          message,
          errorCode,
        },
      });
    }
  };

  return [creationState, createDirectory, resetState] as const;
};
