import get from 'lodash.get';

export const getNewDirectoryErrorCode = (error: unknown): string =>
  get(error, [
    'response',
    'errors',
    '0',
    'extensions',
    'exception',
    'response',
    'errors',
    '0',
    'code',
  ]);
